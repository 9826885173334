import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@app/store/hooks';
import { AuthProps } from '@app/store/auth/types';
import { initialAuthState, setAuth } from '@app/store/auth/authSlice';
import { useGetUserDetailsQuery } from '@app/store/login/api';

interface IAuthStatusDispatcherProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function AuthDispatcher({ children }: IAuthStatusDispatcherProps) {
    const dispatch = useAppDispatch();
    const [is401, setIs401] = useState(false);

    const { data, isLoading } = useGetUserDetailsQuery(undefined, {
        pollingInterval: 10000,
        selectFromResult: ({ data, isLoading, isError }) => {
            if (isError) setIs401(true);
            if (data) {
                return { data: { ...data, isLoading }, isLoading };
            }
            return { data, isLoading };
        },
        skip: is401
    });

    useEffect(() => {
        if (data) {
            const user: AuthProps = { ...data, isLoading: false };
            dispatch(setAuth(user));
        }
        if (is401) {
            dispatch(setAuth({ ...initialAuthState, isLoading: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, is401]);
    return <>{children}</>;
}
