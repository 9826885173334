import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {RootState} from '@app/store/store';
import {AuthProps} from '@app/store/auth/types';

export const initialAuthState: AuthProps = {
    customer: null, paymentType: null, remainingUploads: 0,
    name: '',
    userId: '',
    email: '',
    picture: '',
    remainingCredits: 0,
    isLoading: true
};
export const authSlice = createSlice({
    name: 'authSlice',
    initialState: initialAuthState,
    reducers: {
        setAuth: (state, action) => {
            return {...state, ...action.payload};
        }
    }
});

const authReducer = persistReducer(
    {
        key: 'auth:data',
        storage
    },
    authSlice.reducer
);

const reducerObj = {reducerPath: authSlice.name, reducer: authReducer};

export const selectAuth = (state: RootState) => state.authSlice;
export const {setAuth} = authSlice.actions;

export default reducerObj;
