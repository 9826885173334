import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import { MemberDto, MemberInvitationDto } from '@app/store/project/types';
import { capitalize } from '@app/utils/stringUtils';
import { RoleType } from '@app/models/enums/role';
import { useTranslation } from 'next-i18next';
import { router } from 'next/client';
import { useRouter } from 'next/router';

function RoleModal({ member, handleActionButton, newRole }: { member: MemberDto | MemberInvitationDto; newRole: RoleType; handleActionButton: () => void }) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['modal', 'common']);
    const router = useRouter();
    const title =
        'userInfo' in member
            ? t('changeMemberRoleTitle', { email: member.userInfo.email })
            : t('changeInvitationRoleTitle', {
                  email: member.email,
                  newRole: capitalize(t(`roleType.${newRole.toLowerCase()}`, { ns: 'common' }))
              });
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div data-testid="role-modal" className="flex w-full max-w-[466px] flex-col gap-6 rounded-[8px] bg-white pb-10 ">
            <div className="flex justify-between border-b-[1px]  border-b-black-200 p-4">
                <p className="p2 text-black-800">{t('changeRole', { ns: 'common' })}</p>
                <Close onClick={closeModal} className="h-6 w-6 cursor-pointer" />
            </div>
            <div className="flex flex-col gap-6 px-10">
                <div className="flex flex-col gap-2">
                    <p className="h4 text-black-800" dangerouslySetInnerHTML={{ __html: title }}></p>
                    <p
                        className="p2 text-black-700"
                        dangerouslySetInnerHTML={{
                            __html: t('changeMemberRoleDescription', {
                                email: 'userInfo' in member ? member.userInfo.email : member.email,
                                type: router.query.projectId ? t('project', { ns: 'common' }) : t('organization', { ns: 'common' }),
                                newRole: capitalize(t(`roleType.${newRole.toLowerCase()}`, { ns: 'common' }))
                            })
                        }}
                    ></p>
                </div>
                <div className="grid w-full !max-w-[466px] grid-rows-2 gap-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                    <Button className={'h-[45px]  w-full rounded-[4px] !bg-black-800  hover:!bg-black-1'} variant="solid" onClick={closeModal}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <Button
                        className={'h-[45px] w-full rounded-[4px] !bg-blue-500 hover:!bg-blue-600'}
                        variant={'solid'}
                        data-testid="change-role-button"
                        isLoading={isLoading}
                        onClick={() => {
                            setIsLoading(true);
                            handleActionButton();
                        }}
                    >
                        {'userInfo' in member ? t('changeRole', { ns: 'common' }) : t('inviteNow', { ns: 'common' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default RoleModal;
