import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import PdfEditorTextField from '@app/components/ui/input/pdf-editor-text-field';
import Button from '@app/components/ui/button';
import { useModal } from '@app/components/modal-views/context';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';

interface IDeleteProject {
    projectName: string;
    handleDeleteProject: () => void;
}

function DeleteProjectModal({ projectName, handleDeleteProject }: IDeleteProject) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['common', 'modal']);
    const [isLoading, setIsLoading] = useState(false);
    const [inputProjectName, setInputProjectName] = useState('');
    const handleOnChange = (e: any) => {
        setInputProjectName(e.target.value);
    };
    const handleProjectDeletionButtonClick = () => {
        setIsLoading(true);
        if (inputProjectName.toLowerCase() === projectName.toLowerCase()) {
            handleDeleteProject();
            return;
        }
        toast.error(`${t('toast.failedToDeleteProject')}`, { toastId: ToastId.PROJECT_ERROR_TOAST });
        closeModal();
    };
    return (
        <div data-testid="delete-project-modal" className="flex w-full max-w-[466px] flex-col gap-6 rounded-[8px] bg-white pb-10 ">
            <div className="flex justify-between border-b-[1px]  border-b-black-200 p-4">
                <p className="p2 text-black-800">{t('deleteProject')}</p>
                <Close onClick={closeModal} className="h-6 w-6 cursor-pointer" />
            </div>
            <div className="flex flex-col gap-6 px-10">
                <div className="flex flex-col gap-2">
                    <p className="h4 text-black-800">
                        {t('areYouSureYouWantToDelete', { ns: 'modal' })} <span className="font-bold">{projectName}</span>?
                    </p>
                    <p className="p2 text-black-700">{t('projectDeletionDescription', { ns: 'modal' })}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="h4 text-black-800">{t('label.enterProjectName')}</label>
                    <PdfEditorTextField data-testid="project-name-input" onChange={handleOnChange} placeholder={t('placeholder.projectName')} className="!h-12" />
                </div>
                <div className="grid w-full !max-w-[466px] grid-rows-2 gap-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                    <Button className={'h-[45px]  w-full rounded-[4px] !bg-black-800  hover:!bg-black-1'} variant="solid" onClick={closeModal}>
                        {t('cancel')}
                    </Button>
                    <Button
                        data-testid="delete-now"
                        isLoading={isLoading}
                        // disabled={inputProjectName.toLowerCase() !== projectName.toLowerCase()}
                        className={'h-[45px] w-full rounded-[4px] !bg-red-300 hover:!bg-red-500'}
                        variant={'solid'}
                        onClick={handleProjectDeletionButtonClick}
                    >
                        {t('deleteNow')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default DeleteProjectModal;
