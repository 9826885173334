import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import { AccessTokenRequestBody, AuthProps } from '@app/store/auth/types';
import { callbackResponseDto } from '@app/models/dtos/callbackResponseDto';
import { customBaseQueryWithReauth } from '../refresh/customBaseQuery';

export const USER_PATH = 'user';

export const userApi = createApi({
    reducerPath: USER_PATH,
    // refetchOnMountOrArgChange: true,
    // refetchOnReconnect: true,
    // refetchOnFocus: true,
    // keepUnusedDataFor: 0,
    baseQuery: customBaseQueryWithReauth,
    endpoints: (builder) => ({
        getAuthUrl: builder.query<any, string>({
            query: (locale) => ({
                url: '/oauth/authorization',
                method: 'GET',
                params: {
                    locale
                }
            })
        }),
        getAccessToken: builder.query<callbackResponseDto, AccessTokenRequestBody>({
            query: (requestBody) => ({
                url: '/oauth/callback',
                method: 'POST',
                body: requestBody
            })
        }),
        handleLogout: builder.mutation<string, void>({
            query: () => ({
                url: '/user/logout',
                method: 'POST'
            })
        }),
        getUserDetails: builder.query<AuthProps, void>({
            query: () => '/user/profile'
        })
    })
});

export const { useLazyGetAuthUrlQuery, useGetUserDetailsQuery, useGetAccessTokenQuery, useHandleLogoutMutation } = userApi;
