import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import { toast } from 'react-toastify';
import { useRestorePdfMutation } from '@app/store/pdf/api';
import { pdfResponseDto } from '@app/store/pdf/types';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ToastId } from '@app/constants/ToastId';
import { selectPdfInfo, setToggleCleared } from '@app/store/pdf/slice';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import RecoveryIcon from '@app/components/icons/recovery';

export default function RestoreDocumentModal({ documents }: { documents: Array<pdfResponseDto> }) {
    const { closeModal } = useModal();
    const [restorePdf] = useRestorePdfMutation();
    const router = useRouter();
    const pdf = useAppSelector(selectPdfInfo);
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['common', 'modal', 'document']);
    const [isLoading, setLoading] = useState(false);
    const handleRestore = async () => {
        setLoading(true);
        await restorePdf({
            organizationId: router.query.organizationId as string,
            projectId: router.query.projectId as string,
            documentIds: documents.map((document) => document.documentId)
        }).then((response) => {
            if ('data' in response) {
                closeModal();
                dispatch(setToggleCleared(!pdf.toggleCleared));
                toast(`${t('documentRestored', { ns: 'document' })}`, {
                    type: 'success',
                    toastId: ToastId.DOCUMENT_SUCCESS_TOAST
                });
            } else {
                toast(`${t('toast.failedToRestorePdf')}`, { type: 'error', toastId: ToastId.DOCUMENT_ERROR_TOAST });
            }
            setLoading(false);
        });
    };
    return (
        <div data-testid="delete-modal" className="relative z-50 mx-auto min-w-full max-w-full rounded-[8px] bg-white md:max-w-[600px] lg:max-w-[600px]">
            <div className="relative flex flex-col items-start justify-start p-10">
                <div className="flex w-full flex-col items-center justify-center">
                    <RecoveryIcon />
                    <div className="label mb-8 mt-[15px] max-w-[250px] text-center">
                        {t('areYouSureYouWantToRestore', { ns: 'modal' })}
                        <Typography className="label break-all"> {documents.length === 1 ? `”${documents[0].documentName}”` : `${documents.length} ${t('documents', { ns: 'document' })}`}?</Typography>
                    </div>
                </div>
                <div className="flex w-full justify-between gap-4">
                    <Button className="buttonText1 w-[140px] flex-1 border !border-white-4 !bg-white  !text-black-1 hover:!bg-white-4" onClick={() => closeModal()}>
                        {t('cancel')}
                    </Button>
                    <Button data-testid="delete-document-now" className="buttonText1 flex-2 " isLoading={isLoading} variant="solid" color="danger" onClick={handleRestore}>
                        {t('restoreNow')}
                    </Button>
                </div>
            </div>
            <div className="absolute right-3 top-3 cursor-pointer text-gray-600 hover:text-black" onClick={() => closeModal()}>
                <Close className=" h-6 w-6 text-black-2 dark:text-white" />
            </div>
        </div>
    );
}
