import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import { UpdateInvitationProps } from '@app/store/invitation/types';
import { customBaseQueryWithReauth } from '../refresh/customBaseQuery';

export const INVITATION_PATH = 'invitation';

export const invitationApi = createApi({
    reducerPath: INVITATION_PATH,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: customBaseQueryWithReauth,
    endpoints: (builder) => ({
        updateAllInvitation: builder.mutation<any, UpdateInvitationProps>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/invitations/${requestBody.invitationToken}/all`,
                method: 'POST',
                params: {
                    invitationStatus: requestBody.invitationStatus
                }
            })
        })
    })
});

export const { useUpdateAllInvitationMutation } = invitationApi;
