import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import Button from '@app/components/ui/button';
import { useModal } from '@app/components/modal-views/context';
import { useAppSelector } from '@app/store/hooks';
import { selectActiveOrganization, selectActiveProject } from '@app/store/active/slice';
import { useTranslation } from 'next-i18next';
import { router } from 'next/client';
import { useRouter } from 'next/router';

interface IRemoveInvitation {
    email: string;
    handleRemoveInvitation: () => void;
}

function RemoveInvitation({ handleRemoveInvitation, email }: IRemoveInvitation) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['modal', 'common']);
    const activeProject = useAppSelector(selectActiveProject);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    return (
        <div data-testid="remove-invitation-modal" className="flex w-full max-w-[466px] flex-col gap-6 rounded-[8px] bg-white pb-10 ">
            <div className="flex justify-between border-b-[1px]  border-b-black-200 p-4">
                <p className="p2 text-black-800">{t('removeInvitation')}</p>
                <Close onClick={closeModal} className="h-6 w-6 cursor-pointer" />
            </div>
            <div className="flex flex-col gap-6 px-10">
                <div className="flex flex-col gap-2">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('areYouSureYouWantToRemoveInvitation', {
                                email,
                                name: router.query.projectId ? activeProject?.projectName : activeOrganization?.name
                            })
                        }}
                        className="h4 text-black-800"
                    ></p>
                </div>
                <div className="grid w-full !max-w-[466px] grid-rows-2 gap-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                    <Button className={'h-[45px]  w-full rounded-[4px] !bg-black-800  hover:!bg-black-1'} variant="solid" onClick={closeModal}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <Button
                        isLoading={isLoading}
                        className={'h-[45px] w-full rounded-[4px] !bg-red-300 hover:!bg-red-500'}
                        variant={'solid'}
                        data-testid="remove-invitation"
                        onClick={() => {
                            setIsLoading(true);
                            handleRemoveInvitation();
                        }}
                    >
                        {t('removeNow', { ns: 'common' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default RemoveInvitation;
