import {combineReducers, configureStore, Reducer} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {persistStore} from 'redux-persist';

import environments from '@app/configs/environments';
import {RESET_STATE_ACTION_TYPE} from '@app/store/actions/resetState';
import {pdfApi} from '@app/store/pdf/api';
import pdfSlice from '@app/store/pdf/slice';
import authSlice from '@app/store/auth/authSlice';
import {userApi} from '@app/store/login/api';
import {otpApi} from '@app/store/otp/api';
import {organizationApi} from '@app/store/organization/api';
import activeSlice from '@app/store/active/slice';
import {projectApi} from '@app/store/project/api';
import {invitationApi} from '@app/store/invitation/api';
import {configApi} from '@app/store/config/api';
import {stripeApi} from "@app/store/stripe/api";

// Add more middlewares here
const middlewares = [pdfApi.middleware, userApi.middleware, otpApi.middleware, organizationApi.middleware, projectApi.middleware, invitationApi.middleware, stripeApi.middleware, configApi.middleware];

// if (environments.IS_IN_PRODUCTION_MODE) middlewares.splice(0, 1);

const reducers = {
    [pdfApi.reducerPath]: pdfApi.reducer,
    [pdfSlice.reducerPath]: pdfSlice.reducer,
    [activeSlice.reducerPath]: activeSlice.reducer,
    [authSlice.reducerPath]: authSlice.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [otpApi.reducerPath]: otpApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [invitationApi.reducerPath]: invitationApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [configApi.reducerPath]: configApi.reducer
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) {
        state = {} as RootState;
    }

    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    //@ts-ignore
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
            // serializableCheck: {
            //     ignoredActions: [
            //         FLUSH,
            //         REHYDRATE,
            //         PAUSE,
            //         PERSIST,
            //         PURGE,
            //         REGISTER,
            //     ]
            // }
        }).concat(middlewares),
    preloadedState: {},
    devTools: !environments.IS_IN_PRODUCTION_MODE
    // enhancers: environments.IS_IN_PRODUCTION_MODE ? [] : [monitorReducerEnhancer]
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

setupListeners(store.dispatch);
