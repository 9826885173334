import getConfig from 'next/config';

const config = getConfig();
let publicRuntimeConfig: any = {};
let serverRuntimeConfig: any = {};
if (config && config.publicRuntimeConfig) {
    publicRuntimeConfig = config.publicRuntimeConfig;
}

if (config && config.serverRuntimeConfig) {
    serverRuntimeConfig = config.serverRuntimeConfig;
}

const IS_IN_PRODUCTION_MODE = publicRuntimeConfig.NEXT_PUBLIC_NODE_ENV === 'production';
const BASE_DEPLOY_PATH = process.env.BASE_DEPLOY_PATH ?? '';

const environments = {
    // build-time configs
    BASE_DEPLOY_PATH,
    IS_IN_PRODUCTION_MODE,
    CLIENT_SIDE_ENDPOINT_HOST: publicRuntimeConfig.CLIENT_SIDE_API_ENDPOINT_HOST,
    SERVER_SIDE_ENDPOINT_HOST: publicRuntimeConfig.SERVER_SIDE_API_ENDPOINT_HOST,
    API_KEY: publicRuntimeConfig.API_KEY,
    NODE_ENV: publicRuntimeConfig.NEXT_PUBLIC_NODE_ENV,
    VIRTUAL_HOST: publicRuntimeConfig.VIRTUAL_HOST ?? 'http://localhost:8080',
    DOCUMENT_EXTRACT_IS_ENABLE: publicRuntimeConfig.DOCUMENT_EXTRACT_IS_ENABLE ?? false,
    ANNOTATOR_API: publicRuntimeConfig.ANNOTATOR_API,
    MAX_FILE_SIZE: publicRuntimeConfig.MAX_FILE_SIZE ?? 250 * 1024 * 1024,
    DATA_WEBSITE_ID: publicRuntimeConfig.DATA_WEBSITE_ID,
    STANDARD_PLAN_PRICE_ID: publicRuntimeConfig.STANDARD_PLAN_PRICE_ID,
    STARTER_PLAN_PRICE_ID: publicRuntimeConfig.STARTER_PLAN_PRICE_ID,
    PREMIUM_PLAN_PRICE_ID: publicRuntimeConfig.PREMIUM_PLAN_PRICE_ID,
    FRONTEND_URL: publicRuntimeConfig.FRONTEND_URL
};

export default environments;
