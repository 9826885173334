import React from 'react';

function Info(props: React.SVGAttributes<{}>) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8 3.33333V9.33333" stroke="#D68604" strokeWidth="1.33333" strokeLinecap="round" />
            <circle cx="8.00016" cy="12" r="0.333333" fill="#D68604" stroke="#D68604" strokeWidth="0.666667" />
        </svg>
    );
}

export default Info;
