import React from 'react';

function DragAndDropIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg fill="#000000" width="800px" height="800px" viewBox="-5 -5 24 24" xmlns="http://www.w3.org/2000/svg" {...props} preserveAspectRatio="xMinYMin" className="jam jam-upload">
            <path d="M8 3.414v5.642a1 1 0 1 1-2 0V3.414L4.879 4.536A1 1 0 0 1 3.464 3.12L6.293.293a1 1 0 0 1 1.414 0l2.829 2.828A1 1 0 1 1 9.12 4.536L8 3.414zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z" />
        </svg>
    );
}

export default DragAndDropIcon;
