import environments from '@app/configs/environments';

const globalConstants = {
    appName: 'PDF Editor',
    appDesc:
        'This application empowers users to efficiently manage organizations, projects, and PDF documents collaboratively. With a focus on secure and seamless collaboration, users can create organizations, establish various projects within them, and upload PDF files for collaborative editing.',
    title: 'PDF Editor',
    favIcon: 'favIcon',
    twitterHandle: '@pdfEditor',
    titleImg: 'titleImg',
    socialPreview: {
        url: `https://pdfeditor.io/${environments.BASE_DEPLOY_PATH}`,
        title: 'PDF Editor',
        desc: 'This application empowers users to efficiently manage organizations, projects, and PDF documents collaboratively. With a focus on secure and seamless collaboration, users can create organizations, establish various projects within them, and upload PDF files for collaborative editing.',
        image: 'https://s3.eu-central-1.wasabisys.com/pdfeditor/cloud_saas/landing_page.png'
    },
    consoleWarningTitle: `%cStop!`,
    consoleWarningDescription: `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your sensitive information.`,
    imageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']
};

export default globalConstants;
