import { Fragment, useEffect } from 'react';

import { useRouter } from 'next/router';

import { Close } from '@app/components/icons/close';

import { MODAL_VIEW, useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import { Dialog } from '@app/components/ui/dialog';
import { Transition } from '@app/components/ui/transition';
import DeleteConfirmationModal from '@app/components/modal-views/modals/delete-confirmation-modal';
import LoadingModal from '@app/components/modal-views/modals/loading-modal';
import DeleteProjectModal from '@app/components/modal-views/modals/delete-project-modal';
import RemoveMemberModal from '@app/components/modal-views/modals/remove-member-modal';
import RoleModal from '@app/components/modal-views/modals/role-modal';
import RemoveInvitation from '@app/components/modal-views/modals/remove-invitation';
import FilterModal from '@app/components/modal-views/modals/filter-modal';
import CustomUploadDocumentModal from '@app/components/modal-views/modals/custom-upload-document-modal';
import RestoreDocumentModal from './modals/restore-document-modal';

function renderModalContent(view: MODAL_VIEW | string, modalProps: any) {
    switch (view) {
        case 'DELETE_PDF':
            return <DeleteConfirmationModal {...modalProps} />;
        case 'DELETE_PROJECT':
            return <DeleteProjectModal {...modalProps} />;
        case 'REMOVE_MEMBER':
            return <RemoveMemberModal {...modalProps} />;
        case 'ROLE':
            return <RoleModal {...modalProps} />;
        case 'REMOVE_INVITATION':
            return <RemoveInvitation {...modalProps} />;
        case 'LOADING_MODAL':
            return <LoadingModal {...modalProps} />;
        case 'FILTER_BY_MODAL':
            return <FilterModal {...modalProps} />;
        case 'MANUAL_UPLOAD':
            return <CustomUploadDocumentModal {...modalProps} />;
        case 'RESTORE_PDF':
            return <RestoreDocumentModal {...modalProps} />;
        default:
            return <></>;
    }
}

export default function ModalContainer() {
    const router = useRouter();
    const { view, isOpen, closeModal, modalProps } = useModal();

    const closeModalHandler = () => {
        closeModal();
    };

    useEffect(() => {
        // close search modal when route change
        router.events.on('routeChangeStart', closeModalHandler);
        return () => {
            router.events.off('routeChangeStart', closeModalHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-[2500] h-full w-full overflow-y-auto overflow-x-hidden p-4 text-center sm:p-6 lg:p-8 xl:p-10 3xl:p-12" onClose={closeModalHandler}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Dialog.Overlay
                        onClick={(event) => {
                            if (modalProps && !!modalProps['preventCloseOnOutsideClick']) {
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        }}
                        className="fixed inset-0 z-40 cursor-pointer bg-gray-700 bg-opacity-60 backdrop-blur"
                    />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                {view && view !== 'SEARCH_VIEW' && (
                    <span className="inline-block h-full align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                )}

                {/* This element is need to fix FocusTap headless-ui warning issue */}
                <div className="sr-only">
                    <Button size="small" color="gray" shape="circle" onClick={closeModalHandler} className="opacity-50 hover:opacity-80 ">
                        <Close className="h-auto w-[13px]" />
                    </Button>
                </div>

                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-105" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-105">
                    <div data-testid="modal-view" className="relative z-50 inline-block w-full text-left align-middle md:w-fit">
                        {view && renderModalContent(view, modalProps)}
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}
