import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import { AccessTokenRequestBody, AuthProps } from '@app/store/auth/types';
import { customBaseQueryWithReauth } from '../refresh/customBaseQuery';

export const USER_PATH = 'otp';

export const otpApi = createApi({
    reducerPath: USER_PATH,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: customBaseQueryWithReauth,
    endpoints: (builder) => ({
        sendOtp: builder.mutation<any, { email: string }>({
            query: (requestBody) => ({
                url: '/send',
                method: 'POST',
                body: JSON.stringify(requestBody)
            })
        }),
        postVerifyOtp: builder.mutation<any, { email: string; otpcode: number }>({
            query: (requestBody) => ({
                url: '/verify',
                method: 'POST',
                body: JSON.stringify(requestBody)
            })
        })
    })
});

export const { usePostVerifyOtpMutation, useSendOtpMutation } = otpApi;
