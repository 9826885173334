import React from 'react';
import { extractExtensionFromFileName } from '@app/utils/pdfUtils';
import Excel from '@app/assets/image/Excel.png';
import ImageIcon from '@app/assets/image/Image.png';
import Word from '@app/assets/image/Word.png';
import LogoIcon from '@app/components/icons/logo-icon';
import Txt from '@app/assets/image/text.png';
import Message from '@app/assets/image/email.png';
import Image from 'next/image';
import GenericFileIcon from '@app/components/icons/generic-file-icon';

function DocumentLogoSelector({ fileName, size }: { fileName: string; size?: string }) {
    const excelFileTypes = ['xls', 'xlsx'];
    const imageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
    const wordDocFileTypes = ['doc', 'docx'];
    const txtFileTypes = ['txt'];
    const getSize = size ? `!h-[${size}px] !w-[${size}px]` : 'h-6 w-6';
    const fileExtension = extractExtensionFromFileName(fileName);
    if (excelFileTypes.includes(fileExtension)) {
        return (
            <div className={getSize}>
                <Image src={Excel} objectFit="fill" alt="logo" />
            </div>
        );
    } else if (imageFileTypes.includes(fileExtension)) {
        return (
            <div className={getSize}>
                <Image src={ImageIcon} objectFit="fill" alt="logo" />
            </div>
        );
    } else if (wordDocFileTypes.includes(fileExtension)) {
        return (
            <div className={getSize}>
                <Image src={Word} objectFit="fill" alt="logo" />
            </div>
        );
    } else if (txtFileTypes.includes(fileExtension)) {
        return (
            <div className={getSize}>
                <Image src={Txt} objectFit={'fill'} alt={'logo'} />
            </div>
        );
    } else if (['msg', 'eml'].includes(fileExtension)) {
        return (
            <div className={getSize}>
                <Image src={Message} objectFit={'fill'} alt={'logo'} />
            </div>
        );
    } else if (['pdf'].includes(fileExtension)) {
        return <LogoIcon className={getSize} />;
    } else {
        return <GenericFileIcon className={getSize} />;
    }
}

export default DocumentLogoSelector;
