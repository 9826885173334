import React from 'react';
import LogoIcon from '@app/components/icons/logo-icon';
import AnchorLink from '@app/components/ui/links/anchor-link';
import cn from 'classnames';

type SizeNames = 'large' | 'small';
const sizes: Record<SizeNames, string[]> = {
    large: ['h3 text-black-1'],
    small: ['p2 text-black-600']
};

function Logo({ size = 'small' }: { size?: SizeNames }) {
    return (
        <AnchorLink href="/" className="flex items-center gap-2">
            <LogoIcon height={size === 'small' ? 24 : 40} width={size === 'small' ? 24 : 40} />
            <p className={cn('h4', sizes[size])}>PDF Editor</p>
        </AnchorLink>
    );
}

export default Logo;
