import environments from '@app/configs/environments';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

// create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
    credentials: 'include',
    prepareHeaders(headers, api) {
        if ((api.endpoint === 'uploadPdf' || api.endpoint === 'resumeDocumentProcess' || api.endpoint === 'putBlacklist' || api.endpoint === 'putWhitelist') && headers.get('Content-Type') !== 'application/json') {
            // headers.set('Content-Type', 'multipart/form-data');
        } else {
            headers.set('Content-Type', 'application/json');
        }
        return headers;
    }
});

// interceptor to handle refresh token
export const customBaseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error?.data === 'Unauthorized') {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await fetch(`${environments.SERVER_SIDE_ENDPOINT_HOST}/api/oauth/refreshToken`, {
                    method: 'POST',
                    credentials: 'include'
                });
                if (refreshResult.status > 199 && refreshResult.status < 300) {
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    if (!(window.location.pathname === '/' || window.location.pathname.startsWith('/oauth'))) {
                        // Redirect to landing page
                        window.location.href = '/';
                    } else {
                        return {
                            error: {
                                status: 401,
                                statusText: 'Unauthorized',
                                data: 'Token refresh failed. Please log in again.'
                            } as FetchBaseQueryError
                        };
                    }
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};
